<template>
    <div>
      <section class="section section-new-form section-shaped section-lg my-0">
        <div class="shape shape-style-1 bg-gradient-info">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="container pt-lg-md">
            <div class="row justify-content-center align-items-center">

                <div class="col-lg-12">
                    <card gradient="secondary" shadow body-classes="p-lg-5">
                      <form
                ref="newPatientForm"
                @submit.prevent="validateBeforeSubmit"
              >
                            <h4 class="mb-1 display-4 text-center">New Patient Form - Musculoskeletal</h4>
                            <div class="row">
                              <div class="col-lg-12">
<base-input label="Date">
                        <flat-picker slot-scope="{blur}"
                                     @on-close="blur"
                                     :config="{allowInput: true}"
                                     class="form-control datepicker"
                                     v-model="patient.date">
                        </flat-picker>
                    </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6">
<base-input
                            v-model="patient.first_name"
                                        label="First Name"
                                        placeholder="First name"
                                        required
                                        :error="($v.patient.first_name.$error) ? 'First Name is required and must have min length of 4': ''"
                                        @input="$v.patient.first_name.$touch()"
                                        >
                            </base-input>
                              </div>
                              <div class="col-lg-6">
<base-input
                            v-model="patient.last_name"
                                        label="Last Name"
                                        placeholder="Last name"
                                        required
                                        :error="($v.patient.last_name.$error) ? 'Last Name is required and must have min length of 4': ''"
                                        @input="$v.patient.last_name.$touch()"
                                        >
                            </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6">
<base-input
                            v-model="patient.email"
                                        label="Email address"
                                        placeholder="Email address"
                                        required
                                        :error="($v.patient.email.$error) ? 'Email is required': ''"
                                        @input="$v.patient.email.$touch()">
                            </base-input>
                              </div>
                              <div class="col-lg-6">
                                <base-input
                            v-model="patient.phone"
                                        label="Phone"
                                        placeholder="Phone"
                                        >
                            </base-input>
                              </div>
                            </div>

                            <div class="row">
                <div class="col-lg-12 col-md-6">
                    <!-- Checkboxes -->
                    <div class="mb-3">
                        <small class="text-uppercase font-weight-bold">Location</small>
                    </div>
                    <div class="row">
                    <div class="col-lg-4">
                      <base-checkbox class="mb-3" name="Full time in Bali" v-model="patient.location">
                        Full time in Bali
                      </base-checkbox>
                    </div>
                    <div class="col-lg-4">
                      <base-checkbox class="mb-3" name="Part time in Bali" v-model="patient.location">
                        Part time in Bali
                    </base-checkbox>
                    </div>
                    <div class="col-lg-4">
                      <base-checkbox class="mb-3" name="Overseas" v-model="patient.location">
                        Overseas
                    </base-checkbox>
                    </div>
                    </div>
                </div>
            </div>
            <div class="row">
                              <div class="col-lg-12">
<base-input label="Date of Birth">
                        <flat-picker slot-scope="{blur}"
                                     @on-close="blur"
                                     :config="{allowInput: true}"
                                     class="form-control datepicker"
                                     v-model="patient.date_of_birth">
                        </flat-picker>
                    </base-input>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-12">
<base-input class="mb-4" label="Which Health Care issue prompted you to make todays appointment?">
                                    <textarea v-model="patient.health_care_issues" class="form-control" name="name" rows="4"
                                              cols="80" placeholder="Type a message..."></textarea>
                            </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
<base-input class="mb-4" label="Are you facing any other Health Care issues you would like to address?">
                                    <textarea v-model="patient.health_care_issues_to_address" class="form-control" name="name" rows="4"
                                              cols="80" placeholder="Type a message..."></textarea>
                            </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
<base-input class="mb-4" label="Briefly describe the treatment history of your main health care concern: For example; What type of Practitioners you have seen (if any), what medications or supplements you may have tried in the past etc.">
                                    <textarea v-model="patient.health_care_concern" class="form-control" name="name" rows="4"
                                              cols="80" placeholder="Type a message..."></textarea>
                            </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
<base-input class="mb-4" label="Please list/date any hospitalisations, surgeries or past major illnesses">
                                    <textarea v-model="patient.past_illness" class="form-control" name="name" rows="4"
                                              cols="80" placeholder="Type a message..."></textarea>
                            </base-input>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-12">
<base-input class="mb-4" label="Please list any Medications or Supplements you are currently taking.">
                                    <textarea v-model="patient.medications_taking" class="form-control" name="name" rows="4"
                                              cols="80" placeholder="Type a message..."></textarea>
                            </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
<base-input class="mb-4" label="Do you follow a restricted diet, now or in the past?">
                                    <textarea v-model="patient.restricted_diet" class="form-control" name="name" rows="4"
                                              cols="80" placeholder="Type a message..."></textarea>
                            </base-input>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-12">
<base-input
                            v-model="patient.energy_level"
                                        label="Please rate your perceived energy level on a scale of 1-10 (10 is highest)."
                                        placeholder="e.g 8"
                                        >
                            </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
<base-input
                            v-model="patient.stress_level"
                                        label="Please rate on a scale of 1-10 your current level of stress (10 is highest)."
                                        placeholder="e.g 8"
                                        >
                            </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
<base-input
                            v-model="patient.sleep_hours"
                                        label="Approximately how many hours do you sleep per night?"
                                        placeholder="e.g 8"
                                        >
                            </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
<base-input
                            v-model="patient.exercise"
                                        label="How many times per week do you exercise?"
                                        placeholder="e.g 8"
                                        >
                            </base-input>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">

                    <!-- Radio buttons -->
                    <div class="mb-3">
                        <small class="text-uppercase font-weight-bold">How often do you drink alcohol?</small>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <base-radio class="mb-3" name="Daily" v-model="patient.alcohol">
                        Daily
                    </base-radio>

                    <base-radio class="mb-3" name="Weekly" v-model="patient.alcohol">
                        Weekly
                    </base-radio>
                    <base-radio class="mb-3" name="Binge" v-model="patient.alcohol">
                        Binge
                    </base-radio>
                      </div>
                      <div class="col-6">
                        <base-radio class="mb-3" name="3-4 times per week" v-model="patient.alcohol">
                        3-4 times per week
                    </base-radio>
                    <base-radio class="mb-3" name="Occasionally" v-model="patient.alcohol">
                        Occasionally
                    </base-radio>
                    <base-radio class="mb-3" name="Never" v-model="patient.alcohol">
                        Never
                    </base-radio>
                      </div>
                    </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-12">
<base-input
                            v-model="patient.smoker"
                                        label="Are you a smoker?"
                                        placeholder="e.g yes"
                                        >
                            </base-input>
                              </div>
                            </div>

                            <base-alert type="success" icon="ni ni-like-2" dismissible v-if="success">
                <span slot="text"><strong>Sent!</strong> We have recieved your email. Please check your email for further details!</span>
            </base-alert>
            <modal :show.sync="success"
                   gradient="primary"
                   modal-classes="modal-danger modal-dialog-centered">
                <h6 slot="header" class="modal-title" id="modal-title-notification">Your attention is required</h6>

                <div class="py-3 text-center">
                    <i class="ni ni-bell-55 ni-3x"></i>
                    <h4 class="heading mt-4">Email Sent Successfully!</h4>
                    <p>We have recieved your email. Please check your email for further details!</p>
                </div>

                <template slot="footer">
                    <base-button type="white" @click="success = false">Ok, Got it</base-button>
                    <base-button type="link"
                                 text-color="white"
                                 class="ml-auto"
                                 @click="success = false">
                        Close
                    </base-button>
                </template>
            </modal>
            <base-alert type="danger" icon="ni ni-support-16" dismissible v-if="error">
                <span slot="text"><strong>Error!</strong> Some error is observed, please try again. Apologies for the inconvenience</span>
            </base-alert>
            <modal :show.sync="error"
                   gradient="danger"
                   modal-classes="modal-danger modal-dialog-centered">
                <h6 slot="header" class="modal-title" id="modal-title-notification">Your attention is required</h6>

                <div class="py-3 text-center">
                    <i class="ni ni-bell-55 ni-3x"></i>
                    <h4 class="heading mt-4">Apologies for the inconvinance!</h4>
                    <p>Some error is observed, please try again. Apologies for the inconvenience</p>
                </div>

                <template slot="footer">
                    <base-button type="white" @click="error = false">Ok, Got it</base-button>
                    <base-button type="link"
                                 text-color="white"
                                 class="ml-auto"
                                 @click="error = false">
                        Close
                    </base-button>
                </template>
            </modal>
            <base-alert type="danger" icon="ni ni-support-16" v-if="$v.$invalid">
                <span slot="text"><strong>Form Invalid!</strong> Please fill the form Properly.</span>
            </base-alert>
                            <base-button type="info" round block size="lg" native-type="submit" :disabled="$v.$invalid">
                                Submit
                            </base-button>
                      </form>
                        </card>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import flatPicker from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { required, email, minLength } from 'vuelidate/lib/validators'
export default {
  metaInfo: {
    title: 'New Patient Musculoskeletal'
  },
  mixins: [validationMixin],
  components: {
    Modal: () => import('@/components/Modal.vue'),
    flatPicker
  },
  name: 'new-patient-musculoskeletal',
  data () {
    return {

      success: false,
      error: false,
      patient: {
        date: new Date().toISOString().slice(0, 10),
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        location: [],
        date_of_birth: null,
        health_care_issues: null,
        health_care_issues_to_address: null,
        health_care_concern: null,
        past_illness: null,
        medications_taking: null,
        restricted_diet: null,
        energy_level: null,
        stress_level: null,
        sleep_hours: null,
        exercise: null,
        alcohol: null,
        smoker: null
      }
    }
  },
  validations: {
    patient: {
      first_name: {
        required,
        minLength: minLength(4)
      },
      last_name: {
        required,
        minLength: minLength(3)
      },
      email: {
        required,
        email
      }
    }
  },
  methods: {
    async validateBeforeSubmit () {
      this.$v.$touch()
      if (!this.$v.$error) {
        console.log('Form valid')
        this.saveForm()
      }
    },
    async saveForm () {
      const loader = this.$loading.show({ container: this.$refs.newPatientForm })
      try {
        const response = await window.axios.post('/api/forms/new-patient-musculoskeletal', {
          data: this.patient
        })
        loader.hide()
        this.success = true
        console.log(response.data.ResponseHeader.ResponseMessage)
      } catch (error) {
        if (error) {
          this.error = true
          loader.hide()
          console.log('Some Error Happened')
        }
      }
    }
  }
}
</script>
<style scoped>
</style>
