<template>
  <div>
    <section class="section-natural-medicine section-shaped my-0"
    :style="{'background-image': 'url(' + $options.filters.imgsrc('img/natural-medicine-1.jpg') + ')'}">
      <div class="shape shape-style-1 shape-warning bg-gradient-warning">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container shape-container d-flex align-items-center text-center">
        <div class="col px-0">
          <div class="row justify-content-center align-items-center">
            <div class="col-lg-8">
              <h1 class="display-1 text-white">Natural medicine</h1>
                                              <p class="lead  text-white">Natural medicine is a system that uses natural
              remedies to help the body heal from disease.</p>

              <div class="btn-wrapper">
                <!-- <base-button tag="a" href="/" type="black" icon="ni ni-user-run"
                  class="mb-3 mb-sm-0 btn-dark">
                  Remove button
                </base-button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section my-5 p-0">
      <div class="container">
        <div class="row text-center justify-content-center">
          <div class="col-lg-12">
            <h2 class="display-3 text-white">Natural medicine overview</h2>
            <p class="lead text-left text-white">A significant point of difference from the western medical model is that it
              is focused on preventing illness - that is, optimising health rather than waiting for
              illness to strike.
            </p>
          </div>
        </div>
        <div class="row row-grid align-items-center">
          <div class="col-md-6 py-5">
            <card class="border-0" hover shadow body-classes="p-0 text-center">
              <div class="card-img-div" :style="{'background-image': 'url(' + $options.filters.imgsrc('img/chinese-herbs.jpg') + ')'}">
                <!-- <img v-lazy="$options.filters.imgsrc('img/chinese-herbs.jpg')"
                class="card-img-top img-fluid" /> -->
              </div>

              <h6 class="text-dark font-weight-bold mt-2">TRADITIONAL CHINESE HERBS FORMULAS (vegan only)
              </h6>
              <p class="description p-3 text-left mt-3">In Chinese herbal medicine, we generally don't treat with individual herbs. Instead,
              we use a combination of many herbs designed to match the individual's pattern, derived
              by a complex diagnostic process. <br>
              By combining many herbs, we can balance the effects of the most potent herbs. Dang
              Gui, for example, is a potent blood-tonic herb, but it's also quite heating. <br>
              For example, blood-deficient women with heat symptoms such as acne, headaches and
              facial flushing, Dang Gui alone would be a disaster. <br>
              Yet in Su Wu Tang – the herbal formula recommended for the blood deficiency pattern –
              Dang Gui is safe to consume because cooling blood-tonic herbs are added to the mix,
              reducing its heating effects.
              </p>
            </card>
          </div>
          <div class="col-md-6 py-5">
            <card class="border-0" hover shadow body-classes="p-0 text-center">
              <div class="card-img-div" :style="{'background-image': 'url(' + $options.filters.imgsrc('img/naturopathic-supplements.jpg') + ')'}">
                <!-- <img v-lazy="$options.filters.imgsrc('img/naturopathic-supplements.jpg')"
                class="card-img-top" /> -->
              </div>

              <h6 class="text-dark font-weight-bold mt-2">NATUROPATHIC SUPPLEMENTS
              </h6>
              <p class="description p-3 text-left mt-3">
                Supplements complement herbal medicines and reinforce clinical outcomes. <br>
              They deliver clinical results when they're appropriately taken ie. in the right
              molecular form for absorption and administered as per the clinically trialled dose.
              Unfortunately, the current retail market is saturated with inferior-quality products,
              and consumers are not informed enough to know what they are purchasing. <br>
              More often than not, people are not taking the right dose and, as you can imagine, the
              results are unsatisfactory. A consultation with a qualified practitioner ensures you
              are getting supplements that work.
              <br>
              <br>
              <br>
              </p>
            </card>
          </div>
          <div class="col-md-6 py-5">
            <card class="border-0" hover shadow body-classes="p-0 text-center">
              <div class="card-img-div" :style="{'background-image': 'url(' + $options.filters.imgsrc('img/food-therapy.jpg') + ')'}">
                <!-- <img v-lazy="$options.filters.imgsrc('img/food-therapy.jpg')"
                class="card-img-top" /> -->
              </div>

              <h6 class="text-dark font-weight-bold mt-2">FOOD THERAPY AND LIFESTYLE MEDICINE
              </h6>
              <p class="description p-3 text-left mt-3">
                No discussion about health can be complete without discussing diet and lifestyle.
              Hippocrates's original Greek oath literally says, I will apply dietetic and lifestyle
              measures to help the sick to my best ability and judgment. <br>
              What we do and think every day impacts our energy, which is why it's so important to
              refine our lifestyle choices through appropriate diet, exercise and lifestyle
              practices – all of which are covered in every consultation.<br>
              After all, Natural Medicine in essence is a holistic approach. We consider all parts
              of the human being, body, mind and spirit.
              </p>
            </card>
          </div>
          <div class="col-md-6 py-5">
            <card class="border-0" hover shadow body-classes="p-0 text-center">
              <div class="card-img-div" :style="{'background-image': 'url(' + $options.filters.imgsrc('img/moxibustion.jpg') + ')'}">
                <!-- <img v-lazy="$options.filters.imgsrc('img/moxibustion.jpg')"
                class="card-img-top" /> -->
              </div>

              <h6 class="text-dark font-weight-bold mt-2">MOXIBUSTION (MOXA)
              </h6>
              <p class="description p-3 text-left mt-3">
                Moxibustion involves the burning of herbs, namely mugwort (artemisia vulgaris), over
              acupuncture points. <br>
              Moxa helps move Qi (energy) increase blood circulation and warm cold conditions. There
              are different processing methods of mugwort, and they are used to treat different
              areas of the body. <br>
              For example, Moxibustion can be used on the end of acupuncture needles to heat them,
              burned in a moxa box over a large area such as the abdomen, rolled into cones and
              applied directly to the skin, or administered from a two-inch distance with a moxa
              stick.
              </p>
            </card>
          </div>
        </div>
        </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'naturalmedicine',
  metaInfo: {
    title: 'Natural Medicine'
    // override the parent template and just use the above title only
    // titleTemplate: null
  }
}

</script>
<style scoped>
.card-img-div{
  height: 450px;
  /*
  position: relative;
  overflow: hidden; */
  /* background-position: 0 0px; */
  background-position: center;
  background-size: cover;
}
</style>
