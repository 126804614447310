<template>
<div>
    <header class="header" :class="{ 'change':fixedHeader}">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-3">
                    <div class="headList hidden-xs">
                        <ul>
                            <li>
                                <router-link to="/blog">Blog</router-link>
                            </li>
                            <li>
                                <router-link to="/about">About</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6 text-center">
                    <div class="headerMobile">
                        <div class="logo">
                          <router-link to="/">
                          <div class="logo-heading">Natural Medicine Portal</div>
                          </router-link>
                        </div>

                        <div class="rightBtns mobileSideMenu">
                          <router-link to="/forms" class="btn black-btn" @click.native="closeNav"> Forms </router-link>
                            <a href="#" class="header-menu-button js-header-menu-button" @click="openNav" v-show="!openNavigation"
                                aria-label="Click to toggle Menu" aria-controls="header-menu" aria-expanded="false">
                                <i class="fa fa-bars text-black"></i>
                                </a>
                            <a href="javascript:void(0)" class="closebtn" @click="closeNav"
                                style="display: none;" v-show="openNavigation">&times;</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 text-right">
                    <div class="rightBtns mobile">
                      <ul>
                            <li>
                                <router-link to="/contact" class="contact">
                                Contact
                                </router-link>
                            </li>
                            <li>
                              <!-- <router-link to="/forms" class="btn black-btn"> Forms </router-link> -->
                              <router-link to="/forms" class="contact"> Forms </router-link>

                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <nav class="navbar navbar-expand-lg navbar-light bg-light">
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarNav">
                            <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
                                <!-- <li class="nav-item">
                                    <router-link to="/" class="nav-link">Home</router-link>
                                </li> -->

                                <li class="nav-item dropdown position-static">
                                    <a href="#" class="nav-link" id="navbar-primary_courses" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <!-- <i class="ni ni-collection d-lg-none"></i> -->
                                        <span class="nav-link-inner--text">Courses</span>
                                    </a>
                                    <div class="dropdown-menu w-100" aria-labelledby="navbar-primary_courses">
                                      <div class="container">
                                      <div class="row p-1">
                                          <div class="col-4" v-for="course in courses" :key="course.name">

                                            <!-- <router-link :to="course.link" class="dropdown-item"> -->
                                              <a :href="course.link" class="dropdown-item">
                                              <div>
                                                  <img v-lazy="$options.filters.imgsrc(course.image)" :alt="course.name" class="img-fluid img-grayscale">
                                              </div>
                                              <div class="header-submenu__item-content">
                                                  <div class="header-submenu__item-title h5">{{ course.name }}</div>
                                                  <div class="p header-submenu-list__item-description">{{ course.description }}</div>
                                              </div>
                                              </a>
                                            <!-- </router-link> -->
                                          </div>
                                      </div>
                                      </div>
                                    </div>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/acupuncture" class="nav-link">Acupuncture</router-link>
                                </li>
                                <!-- <transition name="bounce">
                                <li class="nav-item fixed-logo" v-show="fixedHeader">
                                    <a class="nav-link" href="#">
                                        <img v-lazy="$options.filters.imgsrc('img/logo_sm.png')" alt="">
                                    </a>
                                </li>
                                </transition> -->
                                <li class="nav-item">
                                    <router-link to="/natural-medicine" class="nav-link">Natural Medicine</router-link>
                                </li>
                              <li class="nav-item">
                                    <router-link to="/consults" class="nav-link">Bali Clinic Appointments</router-link>
                                </li>
                              <li class="nav-item">
                                    <router-link to="/online-consultation" class="nav-link">Online Consultation</router-link>
                                </li>

                              <base-dropdown tag="li" class="nav-item">
                    <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
                        <i class="ni ni-collection d-lg-none"></i>
                        <span class="nav-link-inner--text">Patient Portal</span>
                    </a>
                    <router-link :to="pp.link" class="dropdown-item" v-for="pp in patientportalmenus" :key="pp.name">{{pp.name}}</router-link>
                </base-dropdown>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </header>

    <div class="searchBox" v-show="searchBox">
        <div class="container">
            <div class="closeSearch">
                <div class="searchType">
                    <form>
                        <label for="search">Type to Search</label>
                        <input type="text" id="search" name="search" placeholder="">
                        <a href="#">
                            <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
                                <g transform="translate(1 1)" stroke="#000" fill="none" fill-rule="evenodd">
                                    <path d="M8.5 8.5l4 4" stroke-linecap="square"></path>
                                    <circle cx="5" cy="5" r="5"></circle>
                                </g>
                            </svg>
                        </a>
                    </form>
                </div>
                <a href="#" class="closeIco" id="SearchCloseBtn" v-show="searchBox" @click="closeSearch"><i class="fa fa-times" aria-hidden="true"></i></a>
            </div>
        </div>
    </div>

    <div id="mySidenav" class="sidenav" :style="{width: openNavigation ? '100%' : '0'}">
        <!-- <form class="mobileSearch">
            <input type="text" placeholder="Type to Search">
            <a href="#">
                <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
                    <g transform="translate(1 1)" stroke="#000" fill="none" fill-rule="evenodd">
                        <path d="M8.5 8.5l4 4" stroke-linecap="square"></path>
                        <circle cx="5" cy="5" r="5"></circle>
                    </g>
                </svg>
            </a>
        </form> -->
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link" id="MobileCoursesMenu" href="#" @click="MobileCoursesMenu = !MobileCoursesMenu">Courses <span class="text-right iconsDropDown2" :class="{ 'hide' : MobileCoursesMenu }"><i
                            class="fa fa-plus" aria-hidden="true"></i><i class="fa fa-minus hide"
                            aria-hidden="true"></i></span></a>
                <ul class="drop" :class="{ 'show1' : MobileCoursesMenu}">
                    <li v-for="course in courses" :key="course.name">
                        <router-link :to="course.link" @click.native="closeNav(),MobileCoursesMenu = !MobileCoursesMenu">
                        <div class="header-submenu__item">
                                <img v-lazy="$options.filters.imgsrc(course.image)" :alt="course.name" class="img-grayscale">
                            </div>

                            <div class="header-submenu__item-content">
                                <div class="header-submenu__item-title h5">{{ course.name }}</div>
                                <div class="p header-submenu-list__item-description">{{ course.description }}</div>
                            </div>
                            <div class="clearfix"></div>
                        </router-link>
                    </li>
                </ul>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/acupuncture" @click.native="closeNav">
Acupuncture <span class="text-right iconsDropDown4"><i
                            class="fa fa-chevron-right" aria-hidden="true"></i></span>
              </router-link>

            </li>
            <li class="nav-item">
                <router-link class="nav-link" to="/natural-medicine" @click.native="closeNav">Natural Medicine <span class="text-right iconsDropDown4"><i
                            class="fa fa-chevron-right" aria-hidden="true"></i></span>
                            </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" to="/consults" @click.native="closeNav">Bali Clinic Appointments <span class="text-right iconsDropDown4"><i
                            class="fa fa-chevron-right" aria-hidden="true"></i></span></router-link>
            </li>
          <li class="nav-item">
                <router-link class="nav-link" to="/online-consultation" @click.native="closeNav">Online Consultation <span class="text-right iconsDropDown4"><i
                            class="fa fa-chevron-right" aria-hidden="true"></i></span></router-link>
            </li>
          <li class="nav-item">
                <a class="nav-link" id="MobilePPMenu" href="#" @click="MobilePPMenu = !MobilePPMenu">Patient Portal <span class="text-right iconsDropDown2" :class="{ 'hide' : MobilePPMenu }"><i
                            class="fa fa-plus" aria-hidden="true"></i><i class="fa fa-minus hide"
                            aria-hidden="true"></i></span></a>
                <ul class="drop" :class="{ 'show1' : MobilePPMenu}">
                    <li v-for="pp in patientportalmenus" :key="pp.name">
                        <router-link :to="pp.link" @click.native="closeNav(),MobilePPMenu = !MobilePPMenu">

                                <div class="nav-item h5">{{ pp.name }}</div>
                            <div class="clearfix"></div>
                        </router-link>
                    </li>
                </ul>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" to="/contact" @click.native="closeNav">Contact <span class="text-right iconsDropDown4"><i
                            class="fa fa-chevron-right" aria-hidden="true"></i></span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" to="/forms" @click.native="closeNav">Forms <span class="text-right iconsDropDown4"><i
                            class="fa fa-chevron-right" aria-hidden="true"></i></span>
                </router-link>
            </li>
        </ul>
        <div class="headList text-center">
            <ul>
                <li>
                    <router-link to="/" @click.native="closeNav">Home</router-link>
                </li>
                <li>
                    <router-link to="/forms" @click.native="closeNav">Forms</router-link>
                </li>
                <li>
                    <router-link to="/blog" @click.native="closeNav">Blog</router-link>
                </li>
                <li>
                    <router-link to="/about" @click.native="closeNav">About</router-link>
                </li>
            </ul>
        </div>
        <div class="mediaIcons text-center">
            <ul>
                <li>
                    <a target="_blank" rel="noopener" href="http://instagram.com/flowingwomban"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                </li>
                <li>
                    <a target="_blank" rel="noopener" href="https://www.facebook.com/flowingwoman"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                </li>
                <!-- <li>
                    <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                </li> -->
                <!-- <li>
                    <a href="#"><i class="fa fa-pinterest-p" aria-hidden="true"></i></a>
                </li> -->
            </ul>
        </div>
    </div>
</div>
    <!-- <header class="header-global">
      <div class="primary-header align-items-lg-center d-none d-lg-block navbar-transparent">
            <div class="container-fluid">
                <div class="row align-items-center">
                  <div class="col-md-5 col-xl-5">
                    <div class="float-left">
                      <ul class="navbar-nav navbar-nav-hover align-items-lg-center top-left-menu">
              <li class="nav-item">
                <a class="nav-link" href="#">{{ 'Blog' | capitalize }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">{{ 'Shop' | capitalize }}</a>
              </li>

            </ul>
                    </div>
                  </div>
                  <div class="col-md-2 col-xl-2">
                      <div class="align-items-lg-center text-center">
                          <a href="index.html">
                            Logo
                          </a>
                      </div>
                  </div>
                  <div class="col-md-5 col-xl-5">
                      <div class="float-right">
                        <ul class="navbar-nav navbar-nav-hover align-items-lg-center top-right-menu">
              <li class="nav-item">
                <a class="nav-link" href="#">{{ 'Blog' | capitalize }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">{{ 'Shop' | capitalize }}</a>
              </li>

            </ul>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <base-nav class="navbar-main secondary-header" :class="{ 'fixed-top':fixedHeader}" transparent type="" effect="light" expand>
            <div class="row" slot="content-header" slot-scope="{closeMenu}">
                <div class="col-6 collapse-brand">
                    <a href="https://demos.creative-tim.com/vue-argon-design-system/documentation/">
                        <img :src="'img/brand/logo-blue.png' | imgsrc">
                    </a>
                </div>
                <div class="col-6 collapse-close">
                    <close-button @click="closeMenu"></close-button>
                </div>
            </div>

            <div class="row align-items-center w-100">
                  <div class="col-md-5 col-xl-5">
                    <ul class="navbar-nav navbar-nav-hover align-items-lg-center float-right">
              <li class="nav-item">
                <a class="nav-link" href="#">{{ 'Our Method' }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">{{ 'Our Services' }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">{{ 'Fertility' }}</a>
              </li>

            </ul>
                  </div>
                  <div class="col-md-2 col-xl-2">
                      <div class="align-items-lg-center text-center">
                          <div class="">
        <a class="" href="#">
            <img src="//placehold.it/120/ccff00" class="rounded-circle">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".dual-collapse2">
            <span class="navbar-toggler-icon"></span>
        </button>
    </div>
                      </div>
                  </div>
                  <div class="col-md-5 col-xl-5">
                      <div class="float-left">
                        <ul class="navbar-nav navbar-nav-hover align-items-lg-center order-1 order-md-1 mr-auto">
      <li class="nav-item">
                <a class="nav-link" href="#">{{ 'Reproductive Health' }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">{{ 'General Health' }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">{{ 'First Visit' }}</a>
              </li>
                <base-dropdown tag="li" class="nav-item">
                    <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
                        <i class="ni ni-collection d-lg-none"></i>
                        <span class="nav-link-inner--text">{{ 'Examples' | capitalize }}</span>
                    </a>
                    <router-link to="/landing" class="dropdown-item">Landing</router-link>
                    <router-link to="/profile" class="dropdown-item">Profile</router-link>
                    <router-link to="/login" class="dropdown-item">Login</router-link>
                    <router-link to="/register" class="dropdown-item">Register</router-link>
                </base-dropdown>
            </ul>
                      </div>
                    </div>
                </div>
        </base-nav>
    </header> -->
</template>
<script>
import BaseDropdown from '@/components/BaseDropdown'
export default {
  components: {
    BaseDropdown
  },
  data () {
    return {
      fixedHeader: false,
      openNavigation: false,
      searchBox: false,
      MobileCoursesMenu: false,
      MobilePPMenu: false,
      MobileConsultMenu: false,
      courses: [
        {
          name: 'PMS, Period Pain and Hormonal Imbalance',
          link: '/course/pms_and_period_pain_relief',
          description: 'A Self-Help Course to Treat PMS, Period Pain and Hormonal Imbalance with Natural Medicine. Master your cycle.',
          image: 'img/period-pain.jpg'
        },
        {
          name: 'Perimenopause & Menopause',
          link: '/course/menopause',
          description: 'Most women are very ill informed about this life changing transitional process. I will show you a combination of natural methods to end your suffering once and for all in the comfort of your own home.',
          image: 'img/menopause.jpg'
        },
        {
          name: 'Bloating and Digestive Issues',
          link: '/course/bloating-and-water-retention',
          description: 'This is a how to guide to manage bloating and improve your digestion.',
          image: 'img/hormonal-imbalance.jpg'
        }
      ],
      patientportalmenus: [
        {
          name: 'How To Take Your Herbal Granules',
          link: '/how-to-take-your-herbal-granules'
        },
        {
          name: 'How To Use A Moxa Stick',
          link: '/how-to-use-a-moxa-stick'
        },
        {
          name: 'How To Use A Moxa Box',
          link: '/how-to-use-a-moxa-box'
        },
        {
          name: 'Gentle detoxification - Organic Coffee Enema',
          link: '/gentle-detoxification-organic-coffee-enema'
        },
        {
          name: 'Bone Broth Recipe',
          link: '/bone-broth-recipe'
        },
        {
          name: 'Veggie Broth Recipe',
          link: '/veggie-broth-recipe'
        },
        {
          name: 'Nutrient-Rich Foods',
          link: '/nutrient-rich-foods'
        }
      ]
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll (event) {
      if (window.scrollY >= 50) {
        this.fixedHeader = true
      } else {
        this.fixedHeader = false
      }
    },
    openNav () {
      this.openNavigation = true
    },
    closeNav () {
      this.openNavigation = false
    },
    openSearch () {
      this.searchBox = true
    },
    closeSearch () {
      this.searchBox = false
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
